<template>
  <div>
    <div class="vx-row mb-6">
      <b-button
        class="primary mx-4"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="goBack"
      >Go Back</b-button>
    </div>
    <div
      v-if="group_event"
      class="vx-row"
    >
      <listings
        class="w-full"
        :group-event-id="event_id"
      />
    </div>

    <div
      v-else
      class="vx-row"
    >
      <list-event
        class="w-full"
        :inherited-event-i-d="event_id"
      />
    </div>
  </div>
</template>

<script>
import Listings from '../Listings.vue'
import ListEvent from '../ListEvent.vue'

export default {
  components: {
    Listings,
    ListEvent,
  },
  data() {
    return {
      group_event: false,
      event_id: null,
    }
  },

  created() {
    const { event_id } = this.$route.params

    const event_details = this.$store.state.events_data.filter(
      event => event._id === event_id,
    )[0]

    this.event_id = event_id
    if (event_details) {
      if (event_details.event_category === 'group') {
        this.group_event = true
      } else {
        this.group_event = false
      }
    } else {
      this.group_event = true
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
  },
}
</script>
